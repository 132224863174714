.App {
  text-align: center;


}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
input{
  font-family: "Noto Sans Mono", monospace;
  font-optical-sizing: auto;
  font-weight: 400;
  font-size: 14px;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none; /* Remove the default appearance */
    margin: 0; /* Remove margin */
}

/* Hide the spinner arrows for Firefox */
input[type="number"] {
    -moz-appearance: textfield; /* Set the appearance to textfield */
}



/* Добавим стили для новых элементов */
.focus\:ring-green-500:focus {
  ring-width: 2px;
  ring-color: #3a9f21;
  outline: none;
}

textarea {
  resize: vertical;
  min-height: 100px;
}

.grid {
  display: grid;
  gap: 1rem;
}

.transition-colors {
  transition: background-color 0.2s ease;
}

/* Добавить в App.css */
.text-shadow {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.input-shadow {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

/* Для полей ввода */
input, textarea {
  transition: all 0.2s ease;
  border: 1px solid #e2e8f0;
}

input:focus, textarea:focus {
  box-shadow: 0 0 0 3px rgba(56, 161, 105, 0.1);
}